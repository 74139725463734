import { createStore } from "vuex";
import auth from "./auth";

export default createStore({
  state: {
    gettingData: false,
    baseURL: "https://api.krstoken.com",
    gdccScanURL: "https://www.gdccscan.io",
    currentComponent: "Login",
    currentComponentKey: null,
    loadCSS: true,
    MsgModel: { type: null, msg: null, logout: false },
    
    tableHeaders: {
      packages: {
        goToItems: [],
        action: true,
        selectedGoto: "1",
        pagination: "none",
        perPage: 10,
        title: "Purchase History",
        noDataMsg: "No package found",
        searchItems: [
          { name: "Reference No.", value: "referenceNumber" },
          { name: "Amount", value: "amount" },
          { name: "Status", value: "status" },
          { name: "Purchase Date", value: "createdOn" },
          // { name: "Expiry Date", value: "expireOn" },
          // { name: "Purchase Type", value: "purchaseType" },
        ],
        searchSelectItems: {
          status: ["Active", "In-Active"],
          purchaseType: ["Stacking", "USD"],
        },
        content: [
          "#",
          "Reference No.",
          "Package Amount",
          "Purchase Date",
          "Status",
          ],
      },
      directMembers: {
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        pagination: "none",
        title: "Direct Members",
        searchItems: [
          { name: "Full Name", value: "firstName" },
          { name: "Member ID", value: "loginName" },
          { name: "User Name", value: "userName" },
          { name: "Registration Date", value: "createdOn" },
        ],
        searchSelectItems: {},
        noDataMsg: "No direct member found",
        content: [
          "#",
          "Full Name",
          "Member ID",
          "User Name",
          "Date Of Registration",
          "Direct Referrals",
        ],
      },
      levelView: {
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        action: true,
        pagination: "none",
        title: "Level View",
        noDataMsg: "No data found",
        content: ["#", "Level", "Total Members", "Total Purchase", "Action"],
      },

      levelDetails: {
        levelNo: 0,
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        searchItems: [
          { name: "Full Name", value: "firstName" },
          { name: "Member ID", value: "loginName" },
          { name: "User Name", value: "userName" },
          { name: "Registration Date", value: "createdOn" },
        ],
        searchSelectItems: {},
        title: "Level Details",
        noDataMsg: "No data found",
        content: [
          "#",
          "Name",
          "Member ID",
          "User Name",
          "Date Of Registration",
          "Total Purchase",
        ],
      },
    
      incomePackage: {
        totalIncome: "",
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        title: "Total Package Bonus ",
        noDataMsg: "No Package Bonus",
        content: [
          "#",
          "Reference Number",
          "Income Type",
          "Package Amount",
          "Token Rate",
          "Token Amount",
          "Income Date",
        ],
      },

      incomePackageMonthly: {
        totalIncome: "",
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        title: "Total Monthly Investor Bonus ",
        noDataMsg: "No Monthly Investor Bonus",
        content: [
          "#",
          "Reference Number",
          "Income Percentage",
          "Package Amount",
          "Token Rate",
          "Amount",
          "Income Date",
        ],
      },

      incomeDirect: {
        totalIncome: "",
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        title: "Total Direct Bonus ",
        noDataMsg: "No direct Bonus",
        content: [
          "#",
          "Downline Details",
          "Reference Number",
          "Package Amount",
          "Bonus Percentage",
          "Net Amount",
          "Earned Tokens",
          "Date",
        ],
      },
   
    
      incomeLevel: {
        totalIncome: "",
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        title: "Total Level Bonus ",
        noDataMsg: "Level income not found",
        searchItems: [
          { name: "Date", value: "createdOn" },
          { name: "Downline MemberID", value: "downlineLoginName" },
        ],
        searchSelectItems: {},
        content: [
          "#",
          "Date",
          "Downline ID",
          "Level",
          "Reference No.",
          "Package Amount",
          "Bonus Percentage",
          "Net Amount",
        ],
      },
      
      walletSummary: {
        totalIncome: "",
        goToItems: [],
        perPage: 10,
        selectedGoto: "1",
        pagination: "none",
        title: "Total Wallet Balance ",
        noDataMsg: "No data found",
        searchItems: [
          { name: "Date", value: "createdOn" },
          { name: "Type", value: "referenceType" },
          { name: "Details", value: "comments" },
        ],
        searchSelectItems: {
          referenceType: [
            "Fund Add",
            "Direct Bonus",
            "Unilevel Bonus",
            "Return Bonus",
            "Fund Transfer",
            "Receive Fund",
            "Purchase Package",
            "Binary Bonus",
            "Convert Tokens",
            "Convert Tokens to Wallet",
          ],
        },
        content: ["#", "Date", "Type", "Details", "Amount"],
      },

      walletTransferHistory: {
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        pagination: "none",
        title: "Fund Transfer History ",
        noDataMsg: "You have not transferred any fund",
        searchItems: [{ name: "Date", value: "createdOn" }, { name: "To MemberID", value: "toMemberID" }],
        searchSelectItems: {},
        content: ["#", "Date", "Transfer To Account", "Amount"],
      },
    
      TokenWalletLockedSummary: {
        totalReceived: "$0",
        totalTransfer: "0$",
        balance: "$0",
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        action: false,
        pagination: "none",
        title: "KRS Locked Token Wallet ",
        noDataMsg: "No data found",
        searchItems: [
          { name: 'Unlock Date', value: 'unlockOn' },
          { name: 'Reference Number', value: 'refNum' },
          { name: 'Transaction Hash', value: 'hash' },
          { name: 'Description', value: 'description' },
          { name: 'Type', value: 'type' },
        ],
        searchSelectItems: {
          type: ['IN', 'OUT'],
        },
       
        content: [
          "#",
          "Member Details",
          "Reference No.",
          "Type",
          "Locked Duration",
          "Hash",
          "Description",
          "Token Amount",
          "Unlock Date"
        ],

      },
      TokenWalletSummary: {
        totalReceived: "$0",
        totalTransfer: "0$",
        balance: "$0",
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        action: false,
        pagination: "none",
        title: "KRS Token Wallet ",
        noDataMsg: "No data found",
        searchItems: [
          { name: 'Income Date', value: 'createdOn' },
          { name: 'Reference Number', value: 'refNum' },
          { name: 'Transaction Hash', value: 'hash' },
          { name: 'Description', value: 'description' },
          { name: 'Type', value: 'type' },
        ],
        searchSelectItems: {
          type: ['IN', 'OUT'],
        },
        content: [
          "#",
          "Member Details",
          "Reference No.",
          "Type",
          "Hash",
          "Description",
          "Token Amount",
          "Income Date"
        ],
      },
      ListMembers: {
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        pagination: "none",
        selectedGoto: "1",
        pagination: "none",
        title: "Today Registrations ",
        noDataMsg: "No Registration Found",
        content: [
          "#",
          "Full Name",
          "Member ID",
          "User Name",
          "Sponsor ID",
          "Sponsor User Name",
          "Registration Date"
        ],
  
      },
      totalBusiness: {
        goToItems: [],
        selectedGoto: "1",
        perPage: 10,
        pagination: "none",
        selectedGoto: "1",
        pagination: "none",
        title: "Today's Business",
        noDataMsg: "No data Found",
        content: [
          "#",
          "Full Name",
          "Member ID",
          "Sponsor Details",
          "Package",
          "Amount",
          "Purchase Date"
        ],
      },
     
    },
  
  
    TokenWalletLockedSummary: [],
    TokenWalletSummary: [],
    totalBusiness: [],
    ListMembers: [],
    packages: [],
    directMembers: [],
    levelView: [],
    levelDetails: [],
    incomeDirect: [],
    incomeBinary: [],
    incomeLevel: [],
    incomePackage: [],
    incomePackageMonthly: [],
    walletSummary: [],
    walletTransferHistory: [],
  },
  mutations: {
    SET_ROUTE(state, component) {
      state.currentComponent = component;
    },
    SET_GENEALOGY(state, genealogy) {
      state.genealogy = genealogy;
    },
  },
  actions: {},

  getters: {
    getRoute: (state) => {
      return state.currentComponent;
    },
    getPurchasePackages: (state) => {
      return state.purchasePackages;
    },
    getGenealogy: (state) => {
      return state.genealogy;
    },
  },
  modules: {
    auth,
  },
});
