<template>
<div>
  <router-view :key="keyGen"/>
    <SuccessModel />
</div>

</template>

<script>
export default {
  data(){
    return {

    }
    
  },
  methods: {
    keyGen(){
      return Math.floor(Math.random() * 500) + 1;
    }
  },
  created(){
   
  }
}
</script>



