<template>
  <section class="error-area bg_cover" style="background-image: url(assets/images/login-bg.jpg); height: 100vh">
      <div v-if="gettingData" id="overlay" style="display:block; z-index: 100000">
        <div id="loading-wrapper">
          <div id="loader">
            <!-- <img src="/images/loader1.gif" class="img-responsive" style="height: 200px;width: 450px" /> -->
          </div>
        </div>
      </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-8">
                    <div class="login-box">
                        <div class="login-title text-center">
                            <img src="images/logo.png" alt="logo">
                            <h3 class="title">Welcome to KRS Token!</h3>
                        </div>
                        <div class="login-input">


                            <div
                              v-if="generalError"
                              class="alert alert-danger"
                              style="background: red;color: white; font-weight: bold"
                            >
                              <button
                                type="button"
                                class="close"
                                data-dismiss="alert"
                                aria-hidden="true"
                              >
                                ×
                              </button>
                              {{ generalError }}
                            </div>

                            <form action="#">
                                <div class="input-box mt-10">
                                    <input
                                        type="text"
                                        placeholder="User Name"
                                        name="loginName"
                                        id="loginName"
                                        v-model="form.user"
                                        @input="validate"
                                      />

                                      <div
                                        v-if="errors.user"
                                        data-bv-validator="notEmpty"
                                        data-bv-validator-for="captcha"
                                        class="help-block"
                                      >
                                        {{ errors.user }}
                                      </div>
                                </div>

                                <div class="input-box mt-10">
                                     <input
                                      :type="passwordInputType"
                                      placeholder="Password"
                                      name="loginPassword"
                                      id="loginPassword"
                                      @input="validate"
                                      v-model="form.password"
                                    />

                                    <div
                                        v-if="errors.password"
                                        data-bv-validator="notEmpty"
                                        data-bv-validator-for="captcha"
                                        class="help-block"
                                      >
                                        {{ errors.password }}
                                      </div>

                                    <a href="password-reset.html">Forgot Password?</a>
                                </div>
                                <div class="input-btn mt-10">
                                    <button @click.prevent="submit" class="main-btn" type="submit">login  <i class="fal fa-arrow-right"></i></button>
                                    <span>Need an account? <a href="register.html">Signup</a></span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios";
import { mapActions, mapMutations } from "vuex";
import reCaptcha from './../components/reCaptcha';
export default {
  components: {},
  data() {
    return {
      loginType: "member",
      passwordInputType: "password",
      gettingData: false,
      form: { user: "", password: "" },
      errors: { user: "", password: "" },
      generalError: "",
      locals: [
        { text: "English", file: "GB.png" },
        { text: "German", file: "DE.png" },
        { text: "Russian", file: "RU.png" },
        { text: "Portuguese", file: "BR.png" },
        { text: "Spanish", file: "ES.png" },
        { text: "Italian", file: "IT.png" },
        { text: "French", file: "FR.png" },
        { text: "Arab", file: "AR.png" },
        { text: "Korean", file: "KR.png" },
      ],
    };
  },
  computed: {},
 
  methods: {
    showPassword() {
      if (this.passwordInputType === "password") {
        this.passwordInputType = "text";
      } else {
        this.passwordInputType = "password";
      }
    },
    ...mapActions({
      signIn: "auth/signIn",
    }),
    ...mapMutations(["SET_ROUTE"]),
    localimage(file) {
      return `/images/flags/${file}`;
    },
    validate() {
      this.generalError = "";
      if (this.form.user) {
        this.errors.user = "";
      } else {
        this.errors.user = "Please enter username";
      }
      if (this.form.password) {
        this.errors.password = "";
      } else {
        this.errors.password = "Please enter password";
      }

      return true;
    },
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha("login");
      // Do stuff with the received token.
      this.form.gToken = token;
      // this.form.token = 'aaa';
      // this.submit();
    },
    async submit() {
      if (this.validate() && this.gettingData === false) {
        this.gettingData = true;

        // wait for captcah to be
        await this.recaptcha();

        var result = await this.signIn(this.form);
        if (result === "success") {
          this.SET_ROUTE('Dashboard');
          window.location.href = '/';
          // window.location.reload();
        } else {
          if (
            result.status === 400 ||
            result.data.type === "ValidationError" ||
            result.data.type === "GeneralError"
          ) {
            this.generalError = result.data.error;
          }
        }
        this.gettingData = false;
      }
    },
  },
};
</script>
<style scoped>
/* login and register */

.login-container .login-box {
  background-color: #ffffff;
}
.login-container {
  background-color: transparent;
}

.login-bg {
  padding: 0px;
  margin: 0px;
  height: 100vh;
  background: url("/theme/img/login-bg.jpg") no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-size: auto;
  background-size: cover;
  background-position: center center;
}


.help-block {
  color: red;
  padding-top: -2px;
  padding-bottom: 10px;
  font-size: 12px;
}

/* register login css end */
</style>
