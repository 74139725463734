<template>
  <div>
    <div class="form-block form-group">
      <input
        class="form-control"
        :type="type"
        :placeholder="placeholder"
        :name="fieldName"
        autocomplete="off"
        :id="fieldName"
        :value="data"
        @input="update"
        @change="validate"
        style="height: 43px;"
      />
    </div>
    <div class="help-block " v-if="error" :class="errorClass">&nbsp;&nbsp;{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: ['fieldName', 'placeholder', 'error', 'errorClass', 'initialValue', 'data', 'isDisable'],
  emits: ['update:data', 'validateUserName', 'validateSponsorID', 'validateReceiverName', 'validateWalletAddress'],
  computed: {
    type() {
      if (
        this.fieldName === 'loginPassword' ||
        this.fieldName === 'currentPassword' ||
        this.fieldName === 'transactionPassword' ||
        this.fieldName === 'newPassword'
      ) {
        return 'password';
      } else if (this.fieldName === 'amount' || this.fieldName === 'transferAmount') {
        return 'number';
      } else {
        return 'text';
      }
    },
  },
  mounted(){
      if(this.isDisable){
         let elem = document.getElementById(`${this.fieldName}`);
         elem.setAttribute('disabled', 'true');
      }
  },
  methods: {
    validate() {
      if (this.fieldName === 'sponsorID') {
        this.$emit('validateSponsorID');
      }
      if (this.fieldName === 'userName') {
        this.$emit('validateUserName');
      }
      if (this.fieldName === 'receiverUserName') {
        this.$emit('validateReceiverName');
      }
      if (this.fieldName === 'walletAddress') {
        this.$emit('validateWalletAddress');
      }
    },
    update(event) {
      if (this.fieldName === 'userName') {
        event.target.value = event.target.value.toUpperCase();
      }

      this.$emit('update:data', event.target.value);
    },
  },
};
</script>

<style scoped>
.errorRed {
  color: red !important;
  font-size: 12px;
}

.errorWhite {
  color: white !important;
  font-size: 13px;
}
</style>
