import axios from 'axios';

export default {
  namespaced: true,
  state: {
    token: null,
    user: {
      firstName: '',
      username: '',
      memberID: '',
      emailAddress: '',
      mobileNumber: '',
      registedOn: '',
      sponsorID: '',
      sponsorUserName: '',
      placementId: '',
      placementUserName: '',
      placement: '',
      stateName: '',
      cityName: '',
      incomeWalletBalance: '',
      activeInvestment: '',
      photo: '',
    },
  },

  getters: {
    authenticated: (state) => {
      if (state.token && state.user) {
        return true;
      } else {
        return false;
      }
    },
    user: (state) => {
      return state.user;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    UPDATE_USER_BALANCE(state, amount) {
      state.user.incomeWalletBalance = amount;
    },
  },
  actions: {
    async signIn({ dispatch }, formData) {
      try {
        const res = await axios.post(`${this.state.baseURL}/auth/login`, formData);
        if (res.data.status === 'success') {
          dispatch('attempt', { user: res.data.data, token: res.data.token });
          localStorage.setItem('authT', res.data.token);
          let expiresAt = new Date();
          expiresAt = expiresAt.setHours(expiresAt.getHours() + 1);
          localStorage.setItem('authExp', expiresAt);

          return 'success';
        }
      } catch (err) {
        if (err) {
          // console.log(err);
          const { response } = err;
          const { request, ...errorObject } = response;
          return errorObject;
        }
      }
    },
    async attempt({ commit }, data) {
      commit('SET_TOKEN', data.token);
      // commit('SET_USER', data.user);
    },
  },
};
