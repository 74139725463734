import axios from "axios";
import { mapGetters, mapMutation } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  methods: {
    cleanData() {
        (this.$store.state.packages = []),
        (this.$store.state.directMembers = []),
        (this.$store.state.levelView = []),
        (this.$store.state.levelDetails = []),
        (this.$store.state.incomeDirect = []),
        (this.$store.state.incomeBinary = []),
        (this.$store.state.incomeLevel = []),
        (this.$store.state.walletSummary = []),
        (this.$store.state.walletTransferHistory = []);
    },
    isLoggedInAsDifferentAcc() {
      let token = localStorage.getItem("authT");

      if (token && token !== this.$store.state.auth.token) {
        this.toggleMsgModel(
          "Info",
          `You are already loggedIn in other browser tab. Please close all other tabs and then click OK to logout and login again.`,
          true
        );
        return false;
      } else {
        return true;
      }
    },
    validateFields(name, fieldName, newValue, regEx) {
      let valid = true;
      if (regEx) {
        valid = regEx.test(newValue);
      }
      if (!newValue) {
        this.errors[fieldName] = `${name} is required`;
      } else if (!valid) {
        if (
          fieldName === "loginPassword" ||
          fieldName === "transactionPassword"
        ) {
          this.errors[fieldName] =
            "Password must be 8-20 characters long containing atleast one alphabet, numeric, lowercase and upper case character";
        } else {
          this.errors[fieldName] = `Invalid ${name}`;
        }
      } else {
        this.errors[fieldName] = null;
      }
      this.msg.content = null;
    },

    validate() {
      let cansubmit = true;
      // validate fileds on submit if empty
      Object.entries(this.form).forEach(([key, value]) => {
        if (!value) {
          this.errors[key] = `This field is required`;
          cansubmit = false;
        }
      });
      // check if error
      Object.entries(this.errors).forEach(([key, value]) => {
        if (value) {
          cansubmit = false;
        }
      });

      console.log(cansubmit);
      return cansubmit;
    },
    reloadComponent() {
      this.$store.state.currentComponentKey = `${this.getRoute}-${Math.random()
        .toString(16)
        .substr(2, 4)}`;
    },
    toggleMsgModel(type, msg, logout) {
      this.$store.state.MsgModel.type = type;
      this.$store.state.MsgModel.msg = msg;
      if (logout) {
        this.$store.state.MsgModel.logout = logout;
      }
      $("#successModel").modal("toggle");
    },
    successFunc() {
      this.$store.state.walletSummary = [];
      this.$store.state.walletTransferHistory = [];
      this.$store.state.packages = [];
      this.$store.state.referralcoinReceived = [];
      this.$store.state.wallet2coinHistory = [];
    },
    async postData(formData, url, dataType) {

     
      try {
        if (
          this.validate() &&
          !this.gettingData &&
          this.isLoggedInAsDifferentAcc()
        ) {
          this.$store.state.gettingData = true;

          const result = await axios.post(
            `${this.$store.state.baseURL}${url}`,
            formData
          );
          this.$store.state.gettingData = false;

          if (result.data.status === "success") {
            this.$store.state.auth.user.totalWallet = result.data.data;
            this.cleanData();
            this.successFunc();
            this.toggleMsgModel("Success", result.data.msg);
            this.reloadComponent();
          } else if (result.data.type === "ValidationError") {
            this.errors = result.data.errors;
          } else if (result.data.type === "generalError") {
            this.msg = {
              type: "Error",
              content: result.data.error,
              class: "alert-danger",
            };
          } else if (result.data.type === "genericError") {
            this.toggleMsgModel("Info", result.data.error);
          } else {
            this.toggleMsgModel(
              "Error",
              `Something went wrong. Please try again`
            );
            this.reloadComponent();
          }
        }
      } catch (err) {
        this.errorFunction(err);
      }
      this.$store.state.gettingData = false;
    },

    

    errorFunction(err){
      if (err) {
        this.$store.state.gettingData = false;
        const { response } = err;
        // Internet is not working or api cannot be connected
        if (!response) {
          this.toggleMsgModel(
            "Error",
            `Network error. Please click 'OK' to reload the page and try again`,
            true
          );
        } else {
          const { request, ...errorObject } = response;
          if (errorObject.status && errorObject.status === 401) {
            localStorage.removeItem("authT");
            localStorage.removeItem("authExp");
            this.toggleMsgModel(
              "Error",
              `You are logged out. Please click 'OK' to login again`,
              true
            );
          } else {
            this.toggleMsgModel(
              "Error",
              `Something went wrong. Please click 'OK' to reload the page and try again`,
              true
            );
          }
        }
      }
    },

    async getData(url, data) {
      this.$store.state.gettingData = true;
      try {
        if (this.isLoggedInAsDifferentAcc()) {
          const res = await axios.get(`${this.$store.state.baseURL}${url}`, {
            params: data,
          });
          this.$store.state.gettingData = false;
          return res.data;
        }
      } catch (err) {
        this.errorFunction(err);
      }
      this.$store.state.gettingData = false;
    },
  },
};
